import * as React from "react";
import { CheckIcon } from "@chakra-ui/icons";
import {
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Text,
  VStack
} from "@chakra-ui/react";

import { Carousel } from "../../common/Carousel";
import { MediaImage } from "../../common/Media";


interface Props {
  data: any
};


export const ServiceAreasFlexbox = ({
  data
}: Props ) => {
  let county = [];
  let countyData = data.neighborhoods;

  while ( countyData.length > 0 ) {
    county.push( countyData.splice( 0, 3 ) );
  }

  console.log( county );

  let flexDirection = data.order % 2 !== 0 ? "row" : "row-reverse";

  return (
    <Flex
      flexDirection={{
        base: 'column',
        lg: flexDirection === "row" ? "row" : "row-reverse"
      }}
      alignItems={{
        lg: 'center'
      }}
      w="100%"
    >
      <MediaImage
        flex={{
          lg: '1 1 0%'
        }}
        containerWidth={{
          xl: '100%'
        }}
        containerHeight={{
          base: '450px',
          lg: '651px',
          '2xl': '806px'
        }}
        image={ data.image.gatsbyImageData }
        alt={ data.image.alt }
        objectFit="none"
        background="primary.100"
        borderRadius={{
          base: '0',
          lg: flexDirection === "row" ? '0px 24px 24px 0px' : '24px 0px 0px 24px'
        }}
        position={ data.image_position }
        imageMaxW="80%"
        imageMaxH="100%"
      />
      <Flex
        display={{
          base: 'none',
          lg: 'flex'
        }}
        flex="1 1 0%"
        justifyContent="center"
      >
        <VStack
          spacing={ 10 }
          align={{
            lg: 'flex-start'
          }}
          w={{
            lg: '450px'
          }}
        >
          <Heading
            as="h3"
            color="accent.900"
            fontSize="32px"
            fontWeight={ 400 }
            lineHeight="36px"
          >
            { data.county.text }
          </Heading>
          <Grid
            templateColumns="1fr 1fr"
            gap="24px 48px"
          >
            { county.map( ( nodeGroup: any[], _groupIndex: number ) => {
              return (
                <>
                  { nodeGroup.map( ( node: any, _index: number ) => {
                    return (
                      <GridItem
                        key={ _index }
                      >
                        <HStack
                          spacing={ 4 }
                        >
                          <Flex
                            justifyContent="center"
                            alignItems="center"
                            w="40px"
                            h="40px"
                            borderRadius="50%"
                            boxShadow="0px 4px 24px rgba(0, 0, 0, 0.06)"
                          >
                            <CheckIcon
                              color="accent.900"
                            />
                          </Flex>
                          <Text
                            color="accent.900"
                            fontSize="16px"
                            fontWeight={ 400 }
                            lineHeight="21px"
                          >
                            { node.neighborhood.text }
                          </Text>
                        </HStack>
                      </GridItem>
                    )
                  })}
                </>
              )
            })}
          </Grid>
        </VStack>
      </Flex>
      <Container
        display={{
          lg: 'none'
        }}
        maxW={{
          base: '100%',
          sm: '640px',
          md: '768px',
          lg: '1024px',
          xl: '1280px',
          '2xl': '1536px'
        }}
        w="100%"
        padding="1rem"
      >
        <Heading
          as="h3"
          margin="16px 0"
          color="accent.900"
          fontSize="32px"
          fontWeight={ 400 }
          lineHeight="36px"
        >
          { data.county.text }
        </Heading>
        <Carousel
          options={{
            dragFree: true,
            containScroll: "trimSnaps"
          }}
          emblaPadding="16px 0"
        >
          { county.map( ( nodeGroup: any[], _index: number ) => {
            return (
              <VStack
                key={ _index }
                align="flex-start"
                spacing={ 2 }
                position="relative"
                minW="250px"
                w={{
                  base: '250px'
                }}
              >
                { nodeGroup.map( ( node: any, _index: number ) => (
                  <HStack
                    key={ _index }
                    spacing={ 4 }
                  >
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      w="40px"
                      h="40px"
                      borderRadius="50%"
                      boxShadow="0px 4px 24px rgba(0, 0, 0, 0.06)"
                    >
                      <CheckIcon
                        color="accent.900"
                      />
                    </Flex>
                    <Text
                      color="accent.900"
                      fontSize="16px"
                      fontWeight={ 400 }
                      lineHeight="21px"
                    >
                      { node.neighborhood.text }
                    </Text>
                  </HStack>
                ))}
              </VStack>
            )
          })}
        </Carousel>
      </Container>
    </Flex>
  )
}
