import * as React from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";


interface Props {
  data: any
};


export const PromotionsItem = ({
  data
}: Props ) => {
  return (
    <>
      <Flex
        flexDirection={{
          base: 'column',
          md: 'row'
        }}
        justifyContent={{
          md: 'space-between'
        }}
        alignItems={{
          md: 'center'
        }}
        w="100%"
        padding="32px 0"
      >
        <VStack
          align="flex-start"
          spacing={ 6 }
          w={{
            lg: '635px'
          }}
        >
          <Box>
            <Heading
              as="h3"
              color="accent.900"
              fontSize="32px"
              fontWeight={ 400 }
              lineHeight="36px"
            >
              { data.service.text }
            </Heading>
            <Heading
              as="h4"
              color="primary.900"
              fontSize="32px"
              fontWeight={ 400 }
              lineHeight="36px"
            >
              { data.discount.text }
            </Heading>
          </Box>
          <Text
            color="accent.900"
            fontSize="16px"
            lineHeight="21px"
            dangerouslySetInnerHTML={{
              __html: data.description?.html || [],
            }}
          />
        </VStack>
        <Box>
          <Button
            variant="outline"
            size="lg"
            color="accent.900"
            borderColor="accent.900"
            borderRadius="24px"
            _hover={{
              background: 'accent.900',
              color: 'white'
            }}
          >
            Offer Available
          </Button>
        </Box>
      </Flex>
      <Divider />
    </>
  )
}
