import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
  Button,
  Flex,
  Grid,
  Heading,
  Text,
  VStack
} from "@chakra-ui/react";


interface Props {
  heading: string
  description: any
  children: React.ReactNode
};


export const CommonPestsGrid = ( props: Props ) => {
  return (
    <>
      <VStack
        position="relative"
        spacing={ 8 }
        justifyContent="flex-start"
        alignItems="center"
        textAlign="center"
        zIndex={ 20 }
      >
        <Heading
          as="h2"
          w={{
            base: '100%',
            lg: '585px'
          }}
          color="accent.900"
          fontSize="48px"
          fontWeight={ 400 }
          lineHeight="54px"
        >
          { props.heading }
        </Heading>
        <VStack
          spacing={ 8 }
          alignItems="center"
          w={{
            base: '100%',
            sm: '500px'
          }}
        >
          <Text
            className="description"
            color="accent.900"
            fontSize="16px"
            fontWeight={ 400 }
            lineHeight="21px"
            textAlign="center"
            dangerouslySetInnerHTML={{
              __html: props.description || [],
            }}
          />
        </VStack>
      </VStack>
      <Grid
        templateColumns={{
          base: '1fr 1fr',
          md: '1fr 1fr 1fr',
          xl: '1fr 1fr 1fr 1fr 1fr 1fr'
        }}
        gap={{
          base: '16px 16px',
          xl: '9.6px',
          '2xl': '60.8px'
        }}
        w={{
          base: '276px',
          sm: '416px',
          md: '632px',
          xl: '100%'
        }}
        margin={{
          base: '0 auto'
        }}
        padding="58px 0"
      >
        { props.children }
      </Grid>
      <Flex
        justifyContent="center"
      >
        <Button
          as={ GatsbyLink }
          to="/pest-library"
          variant="solid"
          size="lg"
          colorScheme="accent"
          border="1px"
          borderColor="accent.900"
          borderRadius="24px"
          _hover={{
            background: 'transparent',
            color: 'accent.900'
          }}
        >
          See Other Pests
        </Button>
      </Flex>
    </>
  )
}
