import * as React from "react";
import {
  Box,
  Flex,
  Heading,
  Stack,
  Text
} from "@chakra-ui/react";

import { IconFactory } from "../../../common/ScalableVectorGraphics";


interface Props {
  order: number
  icon: string
  heading: string
  description: any
};


export const ProcessItem = ( props: Props ) => {
  return (
    <Stack
      position="relative"
      direction={{
        base: 'column',
        lg: 'row'
      }}
      align={{
        base: 'flex-start',
        lg: 'center'
      }}
      spacing={ 8 }
      w={{
        base: '100%',
        lg: 'auto'
      }}
    >
      <Flex
        position={{
          base: 'absolute',
          lg: 'static'
        }}
        top="120px"
        left={ 0 }
        justifyContent="center"
        alignItems="center"
        w="32px"
        h="32px"
        background="accent.900"
        borderRadius="50%"
      >
        <Text
          color="white"
          fontSize="16px"
          fontWeight={ 700 }
          lineHeight="21px"
        >
          { props.order }
        </Text>
      </Flex>
      <Flex
        justifyContent="center"
        alignItems="center"
        w="120px"
        h="120px"
        background="background.linkwater"
        borderRadius="50%"
      >
        <IconFactory
          icon={ props.icon }
          color="primary.900"
          h="32px"
          w="auto"
        />
      </Flex>
      <Box
        w={{
          lg: '540px'
        }}
      >
        <Heading
          as="h3"
          color="accent.900"
          fontSize="28px"
          fontWeight={ 400 }
          lineHeight="33px"
        >
          { props.heading }
        </Heading>
        <Text
          className="description"
          color="accent.900"
          fontSize="16px"
          fontWeight={ 400 }
          lineHeight="21px"
          dangerouslySetInnerHTML={{
            __html: props.description || [],
          }}
        />
      </Box>
    </Stack>
  )
}
