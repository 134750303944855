import * as React from "react";
import {
  Box,
  Flex,
  Stack,
  Text
} from "@chakra-ui/react";

import { IGatsbyImageData } from "gatsby-plugin-image";

import { MediaImage } from "../../common/Media";
import { VesterIcon } from "../../common/ScalableVectorGraphics";
import { SectionContent } from "../../common/Section";


interface Props {
  heading: string
  description: any
  image: IGatsbyImageData
  alt: string
};


export const GuaranteeFlexbox = ( props: Props ) => {
  return (
    <Box
      position="relative"
      w="100%"
    >
      <Stack
        direction={{
          base: 'column-reverse',
          lg: 'row'
        }}
        justifyContent={{
          base: 'flex-start',
          lg: 'space-between'
        }}
        alignItems={{
          lg: 'center'
        }}
        w="100%"
      >
        <SectionContent
          justifyContent="flex-start"
          alignItems="left"
          componentWidth={{
            lg: '323px',
            xl: '407px',
            '2xl': '490px'
          }}
          padding={{
            base: '97px 0 0',
            lg: '0'
          }}
          textAlign="left"
          heading={ props.heading }
          headingWidth={{
            base: '100%',
            lg: '220px'
          }}
          description={ props.description }
        />
        <MediaImage
          containerWidth={{
            lg: '492px',
            xl: '619px',
            '2xl': '746px'
          }}
          containerHeight={{
            lg: '492px',
            xl: '620px',
            '2xl': '746px'
          }}
          borderRadius="0px 0px 16px 16px"
          image={ props.image }
          alt={ props.alt }
          objectFit="cover"
        />
      </Stack>
      <Stack
        direction="row"
        justify="center"
        alignItems="center"
        spacing={ 8 }
        position="absolute"
        top={{
          base: '35%',
          sm: '58%',
          md: '65%',
          lg: '73.2%'
        }}
        left="50%"
        w={{
          base: '280px',
          xl: '328px'
        }}
        h="110px"
        padding="28px"
        background="white"
        borderRadius="8px"
        transform="translateX(-50%)"
        zIndex={ 2 }
      >
        <Flex
          justifyContent="center"
          alignItems="center"
          minW="40px"
          w="40px"
          minH="40px"
          h="40px"
          background="primary.900"
          borderRadius="50%"
        >
          <VesterIcon
            w="16px"
            h="23px"
            color="white"
          />
        </Flex>
        <Text
          w={{
            lg: '150px',
            xl: '166px'
          }}
          fontSize="12px"
          lineHeight="16px"
        >
          Whether you need an inspection, treatment or preventative service.
        </Text>
      </Stack>
    </Box>
  )
}
