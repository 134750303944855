import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
  Box,
  Button,
  Heading,
  Stack,
  Text,
  VStack
} from "@chakra-ui/react";

import { IGatsbyImageData } from "gatsby-plugin-image";

import { MediaImage } from "../../common/Media";


interface Props {
  direction: "row" | "row-reverse"

  heading: any
  description: any
  image: IGatsbyImageData
  alt: string

  imageBackground?: string
  imageMaxW?: object | string
  imageMaxH?: object | string
};


export const AboutFlexbox = ( props: Props ) => {
  return (
    <Stack
      direction={{
        base: 'column',
        lg: props.direction
      }}
      spacing={{
        base: 16,
        lg: '117px'
      }}
      alignItems="center"
      w="100%"
    >
      <VStack
        position="relative"
        spacing={ 8 }
        justifyContent="flex-start"
        alignItems="left"
        minW={{
          lg: '432px',
          xl: '432px',
          '2xl': '520px'
        }}
        w={{
          lg: '432px',
          xl: '432px',
          '2xl': '520px'
        }}
        h="auto"
        textAlign={{
          base: 'center',
          lg: 'left'
        }}
        zIndex={ 20 }
      >
        <Heading
          as="h2"
          w={{
            base: '100%',
            lg: '308px'
          }}
          color="accent.900"
          fontSize="48px"
          fontWeight={ 400 }
          lineHeight="54px"
        >
          { props.heading }
        </Heading>
        <VStack
          spacing={ 8 }
          alignItems="left"
        >
          <Text
            className="description"
            color="accent.900"
            fontSize="16px"
            fontWeight={ 400 }
            lineHeight="21px"
            textAlign={{
              base: 'center',
              lg: 'left'
            }}
            dangerouslySetInnerHTML={{
              __html: props.description || [],
            }}
          />
        </VStack>
        <Box>
          <Button
            as={ GatsbyLink }
            to="/estimate"
            variant="solid"
            size="lg"
            colorScheme="accent"
            border="1px"
            borderColor="accent.900"
            borderRadius="24px"
            _hover={{
              background: 'transparent',
              color: 'accent.900'
            }}
          >
            Request a quote
          </Button>
        </Box>
      </VStack>
      <MediaImage
        hasFrame
        containerWidth="100%"
        containerHeight={{
          base: '300px',
          sm: '570px',
          md: '620px',
          lg: '412px',
          xl: '651px',
          '2xl': '806px'
        }}
        borderRadius="0px 32px"
        background={ props.imageBackground ? props.imageBackground : 'background.cloudburst' }
        image={ props.image }
        imageMaxW={ props.imageMaxW }
        imageMaxH={ props.imageMaxH }
        alt={ props.alt }
        objectFit="none"
        position="bottom"
      />
    </Stack>
  )
}
