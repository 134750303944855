import * as React from "react";
import {
  Box,
  Flex,
  Grid,
  Heading,
  Text,
  VStack
} from "@chakra-ui/react";

import { IGatsbyImageData } from "gatsby-plugin-image";

import { Carousel } from "../../common/Carousel";
import { MediaImage } from "../../common/Media";
import { VesterIcon } from "../../common/ScalableVectorGraphics";

import { BenefitCard } from "../Card";


interface Props {
  heading: any
  description: any
  image: IGatsbyImageData
  alt: string

  nodes: any[]
};


export const BenefitsFlexbox = ( props: Props ) => {
  const half = Math.ceil( props.nodes.length / 2 );

  const nodes_1 = props.nodes.slice( 0, half );
  const nodes_2 = props.nodes.slice( half );

  return (
    <>
      <VStack
        position="relative"
        spacing={ 8 }
        justifyContent="flex-start"
        alignItems="center"
        padding="0 1rem"
        textAlign="center"
        zIndex={ 20 }
      >
        <Heading
          as="h2"
          w={{
            base: '100%',
            lg: '585px'
          }}
          margin="0 auto"
          color="accent.900"
          fontSize="48px"
          fontWeight={ 400 }
          lineHeight="54px"
        >
          { props.heading }
        </Heading>
        <VStack
          spacing={ 8 }
          alignItems="center"
          w={{
            base: '100%',
            sm: '500px'
          }}
        >
          <Text
            className="description"
            color="accent.900"
            fontSize="16px"
            fontWeight={ 400 }
            lineHeight="21px"
            textAlign="center"
            dangerouslySetInnerHTML={{
              __html: props.description || [],
            }}
          />
        </VStack>
      </VStack>
      <Flex
        flexDirection={{
          base: 'column',
          lg: 'row'
        }}
        alignItems="flex-start"
        w="100%"
        padding="90px 0 0"
      >
        <MediaImage
          flex="1 1 0%"
          borderRadius={{
            lg: '0px 32px 32px 0px'
          }}
          image={ props.image }
          alt={ props.alt }
          objectFit="cover"
        >
          <Box
            position="absolute"
            top={{
              base: '180px',
              sm: '25%',
              lg: '31.4%'
            }}
            left={{
              base: '20%',
              sm: '30%',
              lg: '48.1%'
            }}
            w="20px"
            h="20px"
            background="white"
            borderRadius="3px 3px 3px 0px"
            zIndex={ 50 }
          />
          <Box
            position="absolute"
            top={{
              base: '130px',
              sm: '20%',
              lg: '27.5%'
            }}
            left={{
              base: '6%',
              sm: '22%',
              lg: '40%'
            }}
            w="40px"
            h="40px"
            background="white"
            borderRadius="10px 10px 0px 10px"
            zIndex={ 50 }
          />
          <Flex
            justifyContent="space-between"
            alignItems="center"
            position="absolute"
            top={{
              base: '20px',
              sm: '10%',
              lg: '15.4%'
            }}
            left={{
              base: '10%',
              sm: '30%',
              lg: '47%'
            }}
            w={{
              base: '270px',
              lg: '295px'
            }}
            h={{
              base: '98px',
              lg: '103px'
            }}
            padding={{
              base: '24px',
              lg: '28px'
            }}
            background="white"
            borderRadius="62px 62px 62px 0px"
            zIndex={ 50 }
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              minW="40px"
              w="40px"
              minH="40px"
              h="40px"
              background="primary.900"
              borderRadius="50%"
            >
              <VesterIcon
                w="16px"
                h="23px"
                color="white"
              />
            </Flex>
            <Text
              w="166px"
              fontSize="12px"
              lineHeight="16px"
            >
              We're here to help you. Call or message us to ask a question, book a visit or learn about our services.
            </Text>
          </Flex>
        </MediaImage>
        <Flex
          display={{
            base: 'none',
            sm: 'flex'
          }}
          flex="1 1 0%"
          justifyContent="center"
          w="100%"
        >
          <Grid
            templateColumns={{
              base: '1fr',
              sm: '1fr 1fr'
            }}
            gap={ 8 }
            margin={{
              sm: '48px 0 0',
              lg: '0'
            }}
          >
            <VStack
              spacing={ 8 }
              margin={{
                sm: '127px 0 0'
              }}
            >
              { nodes_1.map( ( node, _index ) => (
                <BenefitCard
                  key={ node.node.uid }
                  data={ node.node.data }
                />
              ))}
            </VStack>
            <VStack
              spacing={ 8 }
            >
              { nodes_2.map( ( node, _index ) => (
                <BenefitCard
                  key={ node.node.uid }
                  data={ node.node.data }
                />
              ))}
            </VStack>
          </Grid>
        </Flex>
        <Flex
          display={{
            base: 'block',
            sm: 'none'
          }}
          flex="1 1 0%"
          justifyContent="center"
          w="100%"
        >
          <Carousel
            options={{
              loop: true,
              skipSnaps: false
            }}
            layout="benefit"
            nodes={ props.nodes }
            dotsEnabled
            emblaPadding={{
              base: '40px 0 24px'
            }}
          />
        </Flex>
      </Flex>
    </>
  )
}
