import * as React from "react";
import { CheckIcon } from "@chakra-ui/icons";
import {
  Flex,
  GridItem,
  Heading
} from "@chakra-ui/react";


interface Props {
  heading: string
}


export const CareerBenefitItem: React.FC<Props> = ( props ) => {
  return (
    <GridItem
      as={ Flex }
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      gap="32px"
      h={{
        base: '200px',
        sm: '220px',
        lg: 'auto'
      }}
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        w="70px"
        h="70px"
        bg="primary.900"
        borderRadius="50%"
        boxShadow="0px 4px 15px rgba(0, 0, 0, 0.25)"
      >
        <CheckIcon
          w="30px"
          h="auto"
          color="white"
        />
      </Flex>
      <Heading
        as="h3"
        color="accent.900"
        fontSize="20px"
        fontWeight={ 500 }
        textAlign="center"
      >
        { props.heading }
      </Heading>
    </GridItem>
  )
}
