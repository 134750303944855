import { graphql } from "gatsby";

import { Layout } from "../components/Layout";
import { Head } from "../components/Head";

import {
  SectionContainer,
  SectionContent,
  SectionWrapper
} from "../common/Section";

import { CallToAction } from "../components/CallToAction";
import { BenefitsCarousel, TestimonialsCarousel } from "../components/Carousel";
import { AboutFlexbox, ServiceAreasFlexbox } from "../components/Flexbox";
import { CareerBenefitsGrid, CareerBenefitItem } from "../components/Grid";
import { PageHero } from "../components/Hero";
import { Stack } from "../components/Stack";


interface Props {
  data: any
};


const AboutPage = ({
  data
}: Props ) => {
  const benefits = data?.allPrismicBenefit?.edges || [];
  const serviceLocations = data?.allPrismicServiceLocation?.edges || [];
  const socials = data?.allPrismicSocial?.edges || [];
  const testimonials = data?.allPrismicTestimonial?.edges || [];

  const calltoaction = data?.prismicCallToAction?.data || {};
  const global = data?.prismicSiteInfo?.data || {};
  
  const aboutpage = data?.prismicAboutPage?.data || {};

  console.log( serviceLocations );

  return (
    <Layout
      global={ global }
      socials={ socials }
    >
      <Head
        title={ aboutpage.title.text }
        description={ aboutpage.description.text }
      />

      <SectionWrapper
        background="background.linen"
      >
        <SectionContainer>
          <PageHero
            hasBackground
            heading={ aboutpage.hero_heading.text }
            description={ aboutpage.hero_description.html }
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="141px 0 97px"
      >
        <SectionContainer>
          <AboutFlexbox
            direction="row-reverse"
            heading={ aboutpage.about_heading.text }
            description={ aboutpage.about_description.html }
            image={ aboutpage.about_image.gatsbyImageData }
            alt={ aboutpage.about_image.alt }
            imageBackground="background.linkwater"
            imageMaxW={{
              base: '45%',
              sm: '320px',
              lg: '200px',
              xl: '350px',
              '2xl': '400px'
            }}
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="0 0 80px"
      >
        <SectionContainer>
          <BenefitsCarousel
            nodes={ benefits }
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="76px 0 100px"
      >
        <SectionContainer>
          <SectionContent
            justifyContent="flex-start"
            alignItems="center"
            textAlign="center"
            padding="0 1rem"
            heading={ aboutpage.service_locations_heading.text }
            headingWidth={{
              base: '100%',
              lg: '585px'
            }}
            headingMargin="0 auto"
            description={ aboutpage.service_locations_description.html }
            descriptionWidth={{
              base: '100%',
              md: '625px'
            }}
          />
        </SectionContainer>
        <Stack
          align="flex-start"
          spacing={{
            base: '50px',
            lg: '100px'
          }}
        >
          { serviceLocations.map(( node: any, _index: number ) => (
            <ServiceAreasFlexbox
              key={ node.node.uid }
              data={ node.node.data }
            />
          ))}
        </Stack>
      </SectionWrapper>

      <SectionWrapper
        padding="80px 0 40px"
      >
        <SectionContainer>
          <SectionContent
            justifyContent="flex-start"
            alignItems="center"
            textAlign="center"
            heading={ aboutpage.career_benefits_heading.text }
            headingWidth={{
              base: '100%'
            }}
            headingMargin="0 auto"
            description={ aboutpage.career_benefits_description.html }
            descriptionWidth={{
              base: '100%',
              sm: '580px'
            }}
          />
          <CareerBenefitsGrid>
            { aboutpage.career_benefits.map(( node: any, _index: number ) => (
              <CareerBenefitItem
                heading={ node.career_benefit.text }
              />
            ))}
          </CareerBenefitsGrid>
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="80px 0 46px"
      >
        <TestimonialsCarousel
          heading={ aboutpage.testimonials_heading.text }
          description={ aboutpage.testimonials_description.html }
          nodes={ testimonials }
        />
      </SectionWrapper>

      <SectionWrapper
        padding="60px 0"
      >
        <SectionContainer>
          <CallToAction
            heading={ calltoaction.heading.text }
            description={ calltoaction.description.html }
          />
        </SectionContainer>
      </SectionWrapper>
    </Layout>
  )
}


export const query = graphql`
  query AboutPageQuery {
    allPrismicBenefit {
      edges {
        node {
          _previewable
          data {
            benefit_heading {
              text
            }
            benefit_icon
            benefit_description {
              text
            }
          }
          uid
        }
      }
    }

    allPrismicServiceLocation(
      sort: {
        fields: data___order
        order: ASC
      }
    ) {
      edges {
        node {
          _previewable
          data {
            county {
              text
            }
            order
            image {
              alt
              gatsbyImageData(
                placeholder: BLURRED
              )
            }
            image_position
            neighborhoods {
              neighborhood {
                text
              }
            }
          }
          uid
        }
      }
    }

    allPrismicSocial {
      edges {
        node {
          _previewable
          data {
            platform
            link {
              url
            }
          }
          uid
        }
      }
    }

    allPrismicTestimonial {
      edges {
        node {
          _previewable
          data {
            author {
              text
            }
            review {
              text
            }
            source
          }
        }
      }
    }

    prismicCallToAction {
      _previewable
      data {
        heading {
          text
        }
        description {
          html
        }
      }
      uid
    }

    prismicSiteInfo {
      _previewable
      data {
        phone_number {
          text
        }
        phone_number_link {
          url
        }

        email {
          text
        }
        email_link {
          url
        }

        address {
          text
        }

        accreditations {
          image {
            alt
            gatsbyImageData(
              height: 40
              placeholder: BLURRED
            )
          }
        }

        copyright {
          text
        }
      }
      uid
    }

    prismicAboutPage {
      _previewable
      data {
        title {
          text
        }
        description {
          text
        }

        hero_heading {
          text
        }
        hero_description {
          html
        }

        about_heading {
          text
        }
        about_description {
          html
        }
        about_image {
          alt
          gatsbyImageData(
            placeholder: BLURRED
          )
        }

        service_locations_heading {
          text
        }
        service_locations_description {
          html
        }

        career_benefits_heading {
          text
        }
        career_benefits_description {
          html
        }
        career_benefits {
          career_benefit {
            text
          }
        }

        testimonials_heading {
          text
        }
        testimonials_description {
          html
        }
      }
    }
  }
`;


export default AboutPage;
